import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-services',
  templateUrl: './homethree-services.component.html',
  styleUrls: ['./homethree-services.component.scss']
})
export class HomethreeServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
