<section class="get-in-touch-area py-4">
    <div class="container">
        <!-- <div class="section-title ffamily01">
            <span class="text-lg">Get In Touch</span>
            <h4 class="ffamily06 color03">Contact Us Today To Speak With An Expert About Your Specific Needs</h4>
        </div> -->

        <form class="get-in-touch-form px-lg-5 px-md-2">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" id="First-Name">
                    </div>
                </div>

                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" id="Email">
                    </div>
                </div>

                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" id="Number">
                    </div>
                </div>

                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label>Company</label>
                        <input type="text" class="form-control" id="Company">
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="form-group">
                        <label>Content</label>
                        <textarea type="text" class="form-control" id="Content"></textarea>
                    </div>
                </div>
            </div>

            <button type="submit" class="default-btn">
                <span>Enquiry</span>
            </button>
        </form>
    </div>
</section>