<section class="banner-area bg-1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span class="top-title">World Class Cyber Security</span>
                    <h1>Our company distributes electronic components.</h1>
                    <p>Cybersecurity is the protection  from the theft to protect of our computer systems and networks or being damaged of our hardware and software.</p>
                    <div class="banner-btn">
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="https://cdn.pixabay.com/photo/2020/12/20/09/12/chip-5846491_1280.jpg" alt="image">
                    <!-- https://cdn.pixabay.com/photo/2020/12/20/09/12/chip-5846491_1280.jpg' -->
                </div>
            </div>
        </div>
    </div>
</section>