<header class="header-area">
    <div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container el-container">
            <nav class="navbar navbar-expand-lg navbar-light " [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/NaamEle.png" alt="Logo" style="height: 93px;"></a> 
                <button class="navbar-toggler" style="display: flex;" type="button" (click)="toggleClass()">
                    <!-- <span routerLink="/cart"><i class="bx bx-user-circle" style="color: white;font-size: 34px;margin-right: 20px;margin-top: -2px;"></i></span> -->
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav my-auto ml-auto">
                        <li class="nav-item">
                            <a  (click)="navigateSection('Home')" class="nav-link active" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="navigateSection('About Us')" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a>
                        </li> 
                        <li class="nav-item">
                            <a (click)="navigateSection('Services')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="navigateSection('Manufacture')" routerLink="/manufacture" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Manufacturer</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="navigateSection('Products')" routerLink="/products" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Products</a>
                        </li>
                        
                        <li class="nav-item">
                            <a (click)="navigateSection('Department')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Department</a>
                        </li>
                        <li class="nav-item">
                            <a  (click)="navigateSection('Contact Us')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                        </li> 
                    </ul>
                    <!-- <div class="others-option">
                        <div class="cart-icon">
                            
                        </div>
                        <div class="cart-icon">
                            <a routerLink="/cart"><i class="bx bx-user-circle"></i></a>
                        </div>
                        <div class="get-quote">
                            <a routerLink="/contact" class="default-btn"><span>Get a Quote</span></a>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</header>

