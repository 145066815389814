<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2 class="mt-md-5 mt-3 ffamily06">Products Details</h2>
      <ul>
        <li>
          <a routerLink="/"><i class="bx bx-home"></i> Home</a>
        </li>
        <li class="active">Products Details</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Product Details Area -->
<section class="product-details-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div style="display:flex;" class="col-lg-6 col-12">
        <div class="scrollimg">
          <div class="scrollimg1">
            <div class="imgslide">
              <ul id="imgmove" class="ulimgpreview">
                <li class="liimgpreview" *ngFor="let imgpreview of productImages">
                  <div class="imgpreview" [ngClass]="{'imgactive': selectedItem == imgpreview}">
                    <div class="imgpreview1">
                      <img (mouseover)="imgpreviewmouseover($event)" class="sideimg" src={{imgpreview}}
                      onerror="this.src = '/assets/img/dummy.jpg'">
                    </div>
                  </div>

                </li>
              </ul>
            </div>
            <div (click)="moveimgup()" class="uparrow">
              <svg width="8" height="15" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="svgup">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#000" class=""></path>
              </svg>
            </div>
            <div (click)="moveimgdown()" class="downarrow">
              <svg width="8" height="15" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg" class="svgup">
                <path d="M16 23.207L6.11 13.161 16 3.093 12.955 0 0 13.161l12.955 13.161z" fill="#000" class=""></path>
              </svg>
            </div>

          </div>

        </div>
        <div class="product-details-image rounded">
          <div class="img-magnifier-container">
            <div class="mag">
              <img (mouseover)="magnify('image0',6)" id="image0" src="{{mainImage}}" width="600" height="400"
              onerror="this.src = '/assets/img/dummy.jpg'"
                class="img-fluid rounded">
            </div>
          </div>
          <div class="container">
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="product-details-desc">
          <h3 class="ffamily06">{{productDetails?.partNo}}</h3>
          <p class="category ffamily06" *ngIf="productDetails?.manufacturerName">Manufacturer: <span>{{productDetails?.manufacturerName}}</span></p>
          <p class="category ffamily06">Latest Update: <span>{{productDetails?.latestUpdateDate}}</span></p>
          <div style="margin-bottom: 10px;">
            <span style="font-size: 14px;margin-top: 8px;"> <svg xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16" style="color: #36b300;">
                <circle cx="8" cy="8" r="8" />
              </svg>
              &nbsp; In Stock
            </span>
          </div>
          <!-- <div class="product-add-to-cart">
            <h3>Quantities:</h3>
            <div class="input-counter">
              <input type="number" min="1" value="1" />
            </div>
          </div> -->
          <button type="submit" class="default-btn ffamily03" (click)="enquiry()">
            <span> Enquiry</span>
          </button>
          <!-- <ul class="social-wrap ffamily06">
            <li><span>Share:</span></li>
            <li>
              <a href="https://www.linkedin.com/company/naam-elektroonika-o%C3%BC/"
                target="https://www.linkedin.com/company/naam-elektroonika-o%C3%BC/"><i class="bx bxl-linkedin"></i></a>
            </li>
          </ul> -->

        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Product Details Area -->

<section class="contactForm m-3">
  <div class="container bg11 text-white py-4 rounded">
    <h3 class="color11 ffamily03 text-md text-center mb-4" style="color: #ffde5a;">ENQUIRY DETAILS</h3>
    <form [formGroup]="enquiryForm" class="get-in-touch-form px-md-5 ng-untouched ng-pristine ng-valid">
      <div formArrayName="productEnquiryList">
        <div *ngFor="let enquiry of enquiryFormArr.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-4 col-6">
              <div class="form-group">
                <label>Part No</label>
                <input type="text" formControlName="partNo" class="form-control"  
                [ngClass]="{ 'is-invalid': enquiryForm.get('partNo')?.errors && enquiryForm.get('partNo')?.touched }"
                id="PartNo">
           
              </div>
            </div>

            <div class="col-md-3 col-6" >
              <div class="form-group">
                <label>Quantity</label>
                <input type="number" formControlName="quantity" class="form-control" id="Quantity">
              </div>
            </div>

            <div  class="col-md-3 col-6"> 
              <div class="form-group">
                <label>Target Price</label>
                <input type="number" formControlName="targetPrice" class="form-control" id="Price">
              </div>
            </div>
            <div class=" col-md-2 col-6 flex align-items-center mt-auto">
              <div class="form-group">
                <label style="margin-bottom: 24px;">Add More Parts</label>
                <span (click)="addenquiry()">
                  <i class='bx bxs-plus-circle text-lg color08'></i>
                </span> &nbsp;&nbsp;
                <span *ngIf="i != 0" (click)="removeenquiry(i)">
                  <i class='bx bxs-minus-circle text-lg color02'></i>
                </span>
              </div>
            </div> 
          </div>
        

          
          </div>
        </div>
      
      <div class="row mt-2">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Name</label>
            <input type="text" formControlName="cusName" class="form-control" [ngClass]="{ 'is-invalid': enquiryForm.get('cusName')?.errors && enquiryForm.get('cusName')?.touched }">
            <div
            *ngIf="enquiryForm.get('cusName')?.errors && enquiryForm.get('cusName')?.touched">
            <div *ngIf="enquiryForm.get('cusName')?.errors?.['required']"
                class="alert alert-danger">Name is required
            </div>
        </div>
          </div>
    
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Email</label>
            <input type="email" formControlName="emailId" class="form-control" [ngClass]="{ 'is-invalid': enquiryForm.get('emailId')?.errors && enquiryForm.get('emailId')?.touched }" >
            <div
            *ngIf="enquiryForm.get('emailId')?.errors && enquiryForm.get('emailId')?.touched">
            <div *ngIf="enquiryForm.get('emailId')?.errors?.['required']"
                class="alert alert-danger">EmailId is required
            </div> 
          </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Phone</label>
            <input type="text" formControlName="contactNo" class="form-control"  [ngClass]="{ 'is-invalid': enquiryForm.get('contactNo')?.errors && enquiryForm.get('contactNo')?.touched }">
            <div
            *ngIf="enquiryForm.get('contactNo')?.errors && enquiryForm.get('contactNo')?.touched">
            <div *ngIf="enquiryForm.get('contactNo')?.errors?.['required']"
                class="alert alert-danger">Phone is required
            </div> 
          </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Subject</label>
            <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': enquiryForm.get('subject')?.errors && enquiryForm.get('subject')?.touched }">
            <div
            *ngIf="enquiryForm.get('subject')?.errors && enquiryForm.get('subject')?.touched">
            <div *ngIf="enquiryForm.get('subject')?.errors?.['required']"
                class="alert alert-danger">Subject is required
            </div> 
          </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label>Message</label>
            <textarea type="text" formControlName="content" class="form-control" [ngClass]="{ 'is-invalid': enquiryForm.get('content')?.errors && enquiryForm.get('content')?.touched }"
              style="min-height: 150px;"></textarea>
              <div
              *ngIf="enquiryForm.get('content')?.errors && enquiryForm.get('content')?.touched">
              <div *ngIf="enquiryForm.get('content')?.errors?.['required']"
                  class="alert alert-danger">Message is required
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="row my-2 mt-4">
        <div class="col-12 text-center">
          <button type="submit" class="default-btn" (click)="submit()">
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
<!-- Form starts here-->