<section class="banner-area banner-area-two bg-2">
    <div class="container">
        <div class="banner-content ffamily06">
            <span class="top-title ffamily03">Welcome to  NAAM Elektroonika OÜ</span>
            <summary>
            <h1>Professional Electronic Components Distributor</h1>
            <p class="">
                We are a leading provider of high-quality electronic components and solutions for a wide range of industries and applications with high-quality and at relatively low price.
            </p>
            <a routerLink="/about" class="default-btn border-shape2"><span>About Us</span></a>
        </summary>
        </div>
    </div>
</section>