<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title ffamily06">
            <h3 class="color11 ffamily03 text-lg">Our Services</h3>
            <h4>We Provide Complete Set Of Custom-made Services</h4>
        </div>
        <div class="px-lg-5 mx-lg-5 px-2">
            <div class="row">
                <!-- <div class="col-12 col-md-4">
                    <div class="box p-3 bg02 border-shape">
                        <div class="single-services rounded">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="https://cdn.pixabay.com/photo/2015/09/23/15/54/electronics-953932_1280.jpg" alt="Image" class="img-fluid rounded"
                                    style="height: 300px;">
                                </a>
                            </div>
                            <div class="services-content ffamily01">
                                <h3><a routerLink="/services-details">TESTING</a></h3>
                                <div class="content">
                                    <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                                    <a routerLink="/services-details" class="read-more color09">Read More <i class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-12 col-md-4">
                    <div class="box p-3 bg07 border-shape">
                        <div class="single-services rounded">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="https://cdn.pixabay.com/photo/2016/06/20/04/54/testing-circuit-1468062_1280.jpg" alt="Image" class="img-fluid rounded"
                                    style="height: 300px;">
                                </a>
                            </div>
                            <div class="services-content ffamily01">
                                <h3><a routerLink="/services-details">TESTING</a></h3>
                                <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                                <a routerLink="/services-details" class="read-more color10">Read More <i class="flaticon-right-arrow"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="col-12 col-md-6">
                    <div class="box p-3 border-shape">
                        
                        <p>
                            Testing electronic components is an essential process that ensures the proper functioning and reliability of electronic devices.
                            <br/>
                            Our testing process has a wide range of techniques, including visual inspection, continuity testing, functional and performance testing to identify any defects or inconsistencies in the components.
                            We use advanced testing equipments and software to ensure accuracy and consistency in our testing results.

                            <br/>
                            We understand that the success of your project or business depends on the reliability of your electronic components, and we take this responsibility SERIOUSLY.
                        </p>
                        <!-- <div class="single-services rounded">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="https://cdn.pixabay.com/photo/2016/06/20/04/54/testing-circuit-1468062_1280.jpg" alt="Image" class="img-fluid rounded"
                                    style="height: 300px;">
                                </a>
                            </div>
                            <div class="services-content ffamily01">
                                <h3><a routerLink="/services-details">TESTING</a></h3>
                                <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                                <a routerLink="/services-details" class="read-more color10">Read More <i class="flaticon-right-arrow"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
    
                <div class="col-12 col-md-6">
                    <div class="box p-3 bg06 border-shape bsha">
                        <div class="single-services rounded">
                            <div class="services-img simg">
                                <a routerLink="/services-details">
                                    <img src="assets/img/banner/testing_service.png" alt="Testing" class="img-fluid rounded polygonal-img"
                                    style="height: 350px;">
                                </a>
                            </div>
                            <div class="services-content ffamily01">
                                <h3 class="text-center" style="margin-left: -100px;"><a routerLink="/services-details">TESTING</a></h3>
                                <p style="width: 80%;"> Whether you are a manufacturer, designer, or repair technician, testing electronic components is critical to your success.</p>
                                <!-- <a routerLink="/services-details" class="read-more color06">Read More <i class="flaticon-right-arrow"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>