<!-- Start Coming Soon Area -->
<div class="coming-soon-area py-5">
    <div class="row align-items-center"> 
        <div class="col-12 col-md-6 text-md-left">
            <h1 class="color02 ffamily06">COMING SOON</h1>
            <h5 class="">We are coming soon with our Manufacturers</h5>
        </div>
        <div class="col-12 col-md-6 image">
            <img src="assets/img/banner/coming_soon.png" alt="ComeSoon" class="img-fluid p-md-3">
        </div>
    </div>
</div>
<!-- End Coming Soon Area -->


<!-- <div id="timer" class="flex-wrap d-flex justify-content-center">
<div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
<div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
<div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
<div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
</div> -->