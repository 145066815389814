<!-- <app-navbar-style-two></app-navbar-style-two> -->

<!-- Start Page Title Area -->
<!-- <div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="main-contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <div class="section-title ffamily06">
                <h3 class="color11 ffamily03 text-lg">Get In Touch</h3>
                <h4>Contact Us Today To Speak With An Expert About Your Specific Needs</h4>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-6 col-12">
                <app-get-in-touch></app-get-in-touch>
                <!-- <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="contact-title">
                            <h2>Write Us</h2>
                        </div>

                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email Address</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                        <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Subject is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Message</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="10" class="form-control"></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn btn-two" [class.disabled]="!contactForm.valid"><span>Send Message</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> -->
            </div>

            <div class="col-md-6 col-12">
                <div class="contact-info ffamily03">
                    <h3 class="mb-0 text-center">Our Contact Details</h3>
                    <p class="mt-0 mb-2 text-center">Feel free to connect with us.</p>
                    <!-- <div class="container ffamily06">
                        <div class="row match-height">
                            <div class="col-12 col-md-4 boxCon bg10">
                                <div>
                                    <div class="border-bottom color07 font-bold text-center py-2">Logistics</div>
                                    <span class="text-sm">Logistics Supervisor<br>
                                    John<br>
                                    l1@naamel.com</span>
                                    <div class="icon-box">
                                        <i class="bx bxs-truck text02 pos-icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 boxCon bg10">
                            <div>
                                <div class="border-bottom color07 font-bold text-center py-2">Sales</div>
                                <span class="text-sm">sales supervisor<br>
                                Smith<br>
                                sales3@naamel.com</span>
                                <div class="icon-box">
                                    <i class="bx bx-euro text03 pos-icon"></i>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 col-md-4 boxCon bg10">
                            <div>
                                <div class="border-bottom color07 font-bold text-center py-2">Purchasing</div>
                                <span class="text-sm">Purchasing Supervisor<br>
                                Vicky<br>
                                p1@naamel.com</span>
                                <div class="icon-box">
                                    <i class="bx bx-shopping-bag text01 pos-icon"></i>
                                </div>
                            </div>
                            </div>
                        </div> 
                    </div> -->
                    <ul class="address ffamily06 mt-2">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            <span>Address</span>
                            NAAM Elektroonika OÜ <br>
                            Harju maakond, Tallinn, Kesklinna linnaosa, Sakala tn 7-2, 10141
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <span>Phone</span>
                            <a href="tel:+37256306059">+372 56306059</a> 
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <span>Email</span>
                            <a href="mailto:info@naamel.com">info@naamel.com</a>
                            
                        </li>
                    </ul>

                    <div class="sidebar-follow-us ffamily03">
                        <ul class="social-wrap">
                           <li> <h3 class="d-inline-block">Follow us:</h3><a href="https://www.linkedin.com/company/naam-elektroonika-o%C3%BC/" target="https://www.linkedin.com/company/naam-elektroonika-o%C3%BC/"><i class="bx bxl-linkedin"></i></a></li>                         
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div class="map-area">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103674119!2d-73.91097366523668!3d40.85176866829554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1600202608808!5m2!1sen!2sbd"></iframe> -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8116.284214194449!2d24.7488103!3d59.4318895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469295dd1f468591%3A0xed356ed2ff9229ad!2sNAAM%20Elektroonika%20O%C3%9C!5e0!3m2!1sen!2sin!4v1677647089723!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<!-- End Map Area -->