<app-navbar-style-two></app-navbar-style-two>

<app-homeone-banner></app-homeone-banner>

<app-homeone-features></app-homeone-features>

<app-homeone-about></app-homeone-about>

<app-our-challenges></app-our-challenges>

<app-homeone-services></app-homeone-services>

<app-homeone-solution></app-homeone-solution>

<app-get-in-touch></app-get-in-touch>

<app-homeone-protect></app-homeone-protect>

<app-feedback></app-feedback>

<div class="partner-area ptb-100">
    <app-partner></app-partner>
</div>

<app-homeone-blog></app-homeone-blog>