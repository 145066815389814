import {Inject, Injectable, Renderer2} from '@angular/core';
import {environment as env} from 'src/environments/environment';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router'; 
import MetaData from './models/meta-data-model';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    metaTags = {
        OG_TITLE: 'og:title',
        OG_DESCRIPTION: 'og:description',
        OG_IMAGE: 'og:image',
        OG_TYPE: 'og:type',
        OG_URL: 'og:url',
        OG_PRICE:'og:price:amount',
        OG_CURRENCY:'og:price:currency',


        PRODUCT_PRICE:'product:price:amount',
        PRD_CURRENCY:'product:price:currency',
        SALE_PRICE:'product:sale_price:amount',
        SALE_CURRENCY:'product:sale_price:currency',
        SKU:'product:retailer_item_id',
        AVAIL:'product:availability',
        CATALOG_ID:'product:catalog_id',
        BRAND:'product:brand', 
        CONDITION:'product:condition',
        CATEGORY:'product:category',

        CUSTOM_LABEL_0:'product:custom_label_0',
        CUSTOM_LABEL_1:'product:custom_label_1',
        CUSTOM_LABEL_2:'product:custom_label_2',
    };
    defaultMetaData: MetaData = {
        title: `NaamEL - Professional Electronic Components Distributor`,
        description: 'Search and discover the latest designs and deals from America\'s top home brands in one place. Find and share inspiration with shoppable design boards.',
        image: ``,
        type: 'article'
    };

    constructor(
        private titleService: Title,
        private metaService: Meta,
        @Inject(DOCUMENT) private dom,
        private router: Router
        
    ) {
    }

    setMetaTags(data: MetaData,renderer?:Renderer2) {
        data.url = 'https://www.naamel.com' + this.router.url.split('?')[0];
        const metaData = {...this.defaultMetaData, ...data};
        this.titleService.setTitle(metaData.title); 

        this.metaService.updateTag({
            property: this.metaTags.OG_TITLE,
            content: metaData.title
        });
        this.metaService.updateTag({
            property: this.metaTags.OG_DESCRIPTION,
            content: metaData.description
        }); 
        this.metaService.updateTag({
            property: this.metaTags.OG_TYPE,
            content: metaData.type
        });
        this.metaService.updateTag({
            property: this.metaTags.OG_URL,
            content: metaData.url
        });
        this.metaService.updateTag({
            property: this.metaTags.OG_PRICE,
            content: metaData.sellingPrice
        });
        this.metaService.updateTag({
            property: this.metaTags.OG_CURRENCY,
            content: "EUR"
        });

        this.metaService.updateTag({
            property: this.metaTags.PRODUCT_PRICE,
            content: '0'
        });
        this.metaService.updateTag({
            property: this.metaTags.PRD_CURRENCY,
            content: "EUR"
        });

        this.metaService.updateTag({
            property: this.metaTags.SALE_PRICE,
            content: metaData.sellingPrice
        }); 
        this.metaService.updateTag({
            property: this.metaTags.SALE_CURRENCY,
            content: "EUR"
        });
        this.metaService.updateTag({
            property: this.metaTags.AVAIL,
            content: 'Instock'
        }); 
        this.metaService.updateTag({
            property: this.metaTags.BRAND,
            content: 'NaamEl'
        });

        this.metaService.updateTag({
            property: this.metaTags.CONDITION,
            content: "new"
        }); 
        this.metaService.updateTag({
            property: this.metaTags.SKU,
            content: metaData.product_sku
        });

        this.metaService.updateTag({
            property: this.metaTags.CUSTOM_LABEL_0,
            content: metaData.product_condition
        });

        this.metaService.updateTag({
            property: this.metaTags.CUSTOM_LABEL_1,
            content: metaData.date
        });

        this.metaService.updateTag({
            property: this.metaTags.OG_IMAGE,
            content: metaData.image
        });
 

        // this.metaService.updateTag({
        //     property: this.metaTags.SECURE_IMAGE,
        //     content: metaData.image
        // });
        // this.metaService.updateTag({
        //     name: this.metaTags.TWITTER_TITLE,
        //     content: metaData.title
        // });
        // this.metaService.updateTag({
        //     name: this.metaTags.TWITTER_IMAGE,
        //     content: metaData.image
        // });
        if(renderer){
        this.moveMetaTagsToTop(renderer);
        }
    }
    moveMetaTagsToTop(renderer) {
        const head = document.head;
      
        // Get all meta tags in the head section
        const metaTags = Array.from(head.querySelectorAll('meta'));
        // Remove each meta tag from its current position
        metaTags.forEach(meta => {
            renderer.removeChild(head, meta);
        });
      
        // Add each meta tag back to the head section, which will position them at the top
        metaTags.forEach(meta => {
          renderer.appendChild(head, meta);
        });
      }
    setCanonicalURL() {
        const url = 'www.naam' + this.router.url.split('?')[0];
        const head = this.dom.getElementsByTagName('head')[0];
        let element: HTMLLinkElement =
            this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }

    setSchema(data) {
        let imageArr:string[] = [];
        if(data.product_images_gallery&&data.product_images_gallery.length > 0){
      imageArr   = data.product_images_gallery;
      imageArr.unshift(data.main_image);
        } else {
            imageArr.push(data.main_image);
        }
        const isPriceRange = (data.min_price !== data.max_price);
        const minPrice = data.min_price;
        const schema = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: data.name,
            image: imageArr,
            description: 
            data.description[0],
            brand:{
                '@type':'Brand',
                name:data.brand
            },
            aggregateRating:
                data.rating > 0
                    ? {
                        '@type': 'AggregateRating',
                        ratingCount: data.reviews,
                        ratingValue: data.rating
                    }
                    : null,
            offers: {
                '@type': 'Offer',
                url: data.product_url, 
                priceCurrency: 'USD',
                lowprice: data.min_price,
                highPrice:  data.max_price,
                itemCondition:"https://schema.org/NewCondition",
                availability: data.in_inventory ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
            },
            shippingDetails: {
                "@type": "OfferShippingDetails",
                shippingRate: {
                  "@type": "MonetaryAmount",
                  "value": "0",
                  "currency": "USD"
               }
              }
        };
        return schema;
    }

    parseHtmlToString(text): string {
        const htmlComponent = document.createElement('textarea');
        htmlComponent.innerHTML = text;
        return this.removeHtmlTags(htmlComponent.value);
    }

    private removeHtmlTags(value: string): string {
        return value.replace(/(<([^>]+)>)/ig, '');

    }
    /**
 * Set JSON-LD Microdata on the Document Body.
 *
 * @param renderer2             The Angular Renderer
 * @param data                  The data for the JSON-LD script
 * @returns                     Void
 */
public setJsonLd(renderer2: Renderer2, data: any): void {
    let script = renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    renderer2.appendChild(this.dom.head, script);
}
} 