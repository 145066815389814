<section class="our-approach-area-three pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Approach</span>
            <h2>How Can I Protect My Website From Cyber Attacks</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h3>Identifying Threats</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-padlock"></i>
                        </div>
                        <h3>Keep Software Updated</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-url"></i>
                        </div>
                        <h3>Check Website Url</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-antivirus"></i>
                        </div>
                        <h3>Use Antivirus Software</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>