<section class="safety-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Cyber Safety</span>
            <h2>How Can We Keep Uur Website Safe FRom Cyber Crimes</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-1.png" alt="Image">
                        <h3><a routerLink="/services-details">Check Website Url</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-2.png" alt="Image">
                        <h3><a routerLink="/services-details">Check Emails Before Opening Them</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-3.png" alt="Image">
                        <h3><a routerLink="/services-details">Remember To Log Off</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-4.png" alt="Image">
                        <h3><a routerLink="/services-details">Choose Strong Passwords</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-5.png" alt="Image">
                        <h3><a routerLink="/services-details">Shop Safety In Trusted Websites</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <h3><a routerLink="/services-details">Update Your Antivirus</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>