<div class="container partner-area pt-100 pb-70">
    <div class="section-title ffamily06">
        <h3 class="color11 text-lg">Electronic Components Sourcing</h3> 
        <h4 class="ffamily03">We Are Specialists in Electronic Components Sourcing </h4>
    </div>
    <div class="partner-slider">
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide *ngFor="let d of [].constructor(10);let i=index">
                <!-- <div class="partner-item">
                    <a >
                        <img src="assets/img/partner/{{i+1}}.png" alt="Image">
                    </a>
                </div> -->
                <div style="display: flex; align-items: center; height: 25vh;">
                    <img src="assets/img/partner/{{i+1}}.png" alt="NaamEl" style="width: 75%;">
                </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-2.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-3.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-4.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-5.png" alt="Image">
                    </a>
                </div>
            </ng-template> -->
        </owl-carousel-o>
    </div>
</div>