<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="mt-md-5 mt-3 ffamily06">Manufacturer</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Manufacturer</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<div class="product-area">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <!-- <h1>Coming Soon..</h1> -->
                <app-coming-soon-page></app-coming-soon-page>
            </div>
        </div>
    </div>
</div>
<!-- End Products Area -->

