import { Component } from '@angular/core';

@Component({
  selector: 'app-manufacture-page',
  templateUrl: './manufacture-page.component.html',
  styleUrls: ['./manufacture-page.component.scss']
})
export class ManufacturePageComponent {

}
