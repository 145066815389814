import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import MetaData from 'src/app/models/meta-data-model';
import { SeoService } from 'src/app/seo.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-products-details-page',
  templateUrl: './products-details-page.component.html',
  styleUrls: ['./products-details-page.component.scss'],
  providers: [DatePipe],
})
export class ProductsDetailsPageComponent implements OnInit, AfterViewInit {
  selectedItem: any;

  enquiryForm: FormGroup;
  productId: any;
  productDetails: any;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private router: ActivatedRoute,
    private datePipe: DatePipe,
    private seoService:SeoService,
    private _renderer2: Renderer2,
    private titleService: Title
  ) {
    this.enquiryForm = this.fb.group({
      cusEnquiryId: [0],
      productEnquiryList: this.fb.array([]),
      cusName: ['', Validators.required],
      emailId: ['', Validators.required],
      companyName: [''],
      contactNo: ['', Validators.required],
      subject: ['', Validators.required],
      content: ['', Validators.required],
      isActive: [true],
    });
    this.addenquiry();
  }

  get enquiryFormArr(): FormArray {
    return this.enquiryForm.get('productEnquiryList') as FormArray;
  }

  addenquiry() {
    this.enquiryFormArr.push(
      this.fb.group({
        productEnquiryId: [0],
        cusEnquiryId: [0],
        partNo: ['', Validators.required],
        quantity: ['', Validators.required],
        targetPrice: ['', [Validators.required]],
        isActive: [true],
      })
    );
  }

  removeenquiry(index) {
    this.enquiryFormArr.removeAt(index);
  }

  mainImage: string = '';

  productImages = [];

  ngOnInit() {
    this.productId = this.router.snapshot.params.partno;
    this.titleService.setTitle(`NAAMEL - Product - ${this.productId}`)
    this.getProductListbyID(this.productId);
  }
  getProductListbyID(productId) {
    this.productService.getProductListbyId(productId).subscribe((data: any) => {
      if (data) {
        this.productDetails = data;
        this.productDetails['latestUpdateDate'] = this.datePipe.transform(
          this.getRandomDateWithinLastFiveDays(),
          'dd/MM/yyyy'
        );
        this.productDetails.productImage.forEach((element) => {
          if(element.prdImgTypeId===1){
             this.productImages.unshift(
             `https://101.53.158.225/naam-docs/product_images/${element.fileName.replace("C:\\inetpub\\wwwroot\\naam-docs\\product_images\\","")}`
            );
          } else {
          this.productImages.push(
             `https://101.53.158.225/naam-docs/product_images/${element.fileName.replace("C:\\inetpub\\wwwroot\\naam-docs\\product_images\\","")}`
          );
          }
          const metaData:MetaData ={
            title:data.partNo,
            description : `Electronic Components Distributor, naamel ${data.partNo}, Need ${data.partNo}, Buy ${data.partNo}`,
            type: 'Electronic Components',
            url: `https://www.naamel.com/products/${data.partNo}`,
            sellingPrice: '0',
            product_sku: data.partNo,
            date: data.latestUpdateDate,

            image: this.productImages[0]
          }
          data['seo_data'] =  metaData,

          this.mainImage = this.productImages[0];
          this.seoService.setMetaTags(data.seo_data,this._renderer2);
          // this.seoService.setJsonLd(this._renderer2,data.seo_data.json_ld);
        });
      }
    });
  }

  magnify(imgID: any, zoom) {
    var img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement('DIV');
    glass.setAttribute('class', 'img-magnifier-glass');
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize =
      img.width * zoom + 'px ' + img.height * zoom + 'px';
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener('mousemove', moveMagnifier);
    img.addEventListener('mousemove', moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener('touchmove', moveMagnifier);
    img.addEventListener('touchmove', moveMagnifier);
    function moveMagnifier(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /*set the position of the magnifier glass:*/
      glass.style.left = x - w + 'px';
      glass.style.top = y - h + 15 + 'px';
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px';
    }
    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  relatedSlides: OwlOptions = {
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  submit() {
    if (this.enquiryForm.valid) {
      this.productService.saveCustomerEnquiry(this.enquiryForm.value).subscribe(
        (res: any) => {
          alert('Enquiry Submitted Successfully');
          if (res.status == 200) {
            alert('Enquiry Submitted Successfully');
            this.enquiryForm.reset();
          }
        },
        (err: any) => {
          // this.toastr.error(err.error.message);
        }
      );
    } else {
      this.enquiryForm.markAllAsTouched();
    }
  }

  imgpreviewmouseover(event) {
    this.mainImage = event.target.currentSrc;
    this.selectedItem = event.target.currentSrc;
  }

  moveimgup() {
    var imgposition = document.getElementById('imgmove');
    imgposition.style.transform = 'translateY(0px)';
    this.selectedItem = this.productImages[0];
    this.mainImage = this.selectedItem;
  }

  moveimgdown() {
    var imgposition = document.getElementById('imgmove');
    imgposition.style.transform = 'translateY(-274.286px)';
    this.selectedItem = this.productImages[this.productImages.length - 1];
    this.mainImage = this.selectedItem;
  }

  ngAfterViewInit(): void {}
  getRandomDateWithinLastFiveDays(): Date {
    const today = new Date(); // Get the current date
    const fiveDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 4
    ); // Subtract 4 from the current day to get 5 days ago
    const randomTime =
      Math.random() * (today.getTime() - fiveDaysAgo.getTime()) +
      fiveDaysAgo.getTime(); // Get a random time between 5 days ago and now
    const randomDate = new Date(randomTime); // Create a new date object from the random time
    return randomDate;
  }
  enquiry(){
    (this.enquiryFormArr.controls[0] as FormGroup).controls['partNo'].setValue(this.productDetails.partNo);
  }
}
