<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="mt-md-5 mt-3 ffamily06">Products List</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products List</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<div class="product-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8"></div>
            <div class="col-12 col-md-4">
                <div class="sidebar-widget search">
                    <div class="search-form">
                        <input class="form-control" name="search" placeholder="Search here" type="text" placeholder="Search Part No"
                        [(ngModel)]="searchPartNo">
                        <button class="search-button" type="submit" (click)="getProductList({ first: 0, rows: 10 })"><i class="bx bx-search"></i></button>
                    </div>	
                </div>
                <!-- <div class="products-sidebar">
                    <div class="products-sidebar-widget"> 
                        <div class="products-sidebar-search"> 
                            <input type="text" class="input-search" placeholder="Search Part No"
                            [(ngModel)]="searchPartNo">
                            <button type="submit" (click)="getProductList({ first: 0, rows: 10 })"><i class="bx bx-search"></i></button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row match-height">
            <div class="col-lg-4 col-md-4 col-12" 
            *ngFor="let product of productList  
            | paginate: { itemsPerPage: 10, currentPage: page,totalItems:totalRecords }">
                <div class="single-products-box">
                    <div class="product-img">
                        <!-- <span class="iconTop"><i class="bx bx-error-circle text-lg color08"></i></span> -->
                        <a (click)="goToDetails(product)" class="d-block">
                            <img [src]="product.mainPic" alt="Image" class="img-fluid"
                           onerror="this.src = '/assets/img/dummy.jpg'" >
                        </a>
                    </div>
                    <div class="pb-3" (click)="goToDetails(product)">
                        <div class="product-btn">
                            <h3><a>{{product.partNo}}</a></h3>
                        </div>
                        <span>{{product.manufacturer}}</span>
                        <span style="font-size: 12px;margin-top: 8px;"> <svg xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16"
                                style="color: #7ed957;">
                                <circle cx="8" cy="8" r="8" />
                            </svg>
                            &nbsp; In Stock
                        </span>
                    </div>
                    <!-- <div class="py-2">
                        <i class="bx bx-error-circle text-lg color02"></i>
                    </div> -->
                    <!-- <a class="default-btn"><span><Contact us to know more></span></a> -->
                </div>
            </div>


            <div class="col-12 ta-center">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                <!-- <app-pagination [dataSource]="productList" (pageChanged)="pageChanged($event)"></app-pagination> -->
                <!-- <div class="pagination-area">
                    <span class="page-numbers current">1</span>
                    <a routerLink="/products" class="page-numbers">2</a>
                    <a routerLink="/products" class="page-numbers">3</a>
                    <a routerLink="/products" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Products Area -->


