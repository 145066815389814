import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from './http-config.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiUrl: string = environment.apiUrl;
  loadingObs: Subject<boolean> = new Subject();
  
  constructor(private http: HttpConfigService) {}

  saveCustomerEnquiry(data:any){
    return this.http.post(`${this.apiUrl}master/SaveCusEnquiryDetail`, data);
  }
  getProductList(pageData:any){
    return this.http.post(`${this.apiUrl}product/getProductList`,pageData);
  }

  getProductListbyId(productId: any) {
    return this.http.get(`${this.apiUrl}product/getProductDetailById/${productId}`);
  }
}
