import { Component } from '@angular/core';

@Component({
  selector: 'app-hometwo-department',
  templateUrl: './hometwo-department.component.html',
  styleUrls: ['./hometwo-department.component.scss']
})
export class HometwoDepartmentComponent {

}
