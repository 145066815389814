<section class="about-us-area bg09 pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <div class="about-img">
                    <img src="assets/img/banner/Research_Development.gif" alt="Image" class="img-fluid rounded">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-content">
                    <div class="about-title ffamily03 mb-3">
                        <h3 class="color02 text-lg">About Us</h3>
                        <h4>We are a R&D and manufacturing company which specialize in the design, production and sales of electronic products.</h4>
                    </div>
                    <div class="tab">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Our Experience">
                                <p class="ffamily06">
                                    Our company has a rich history in the industry, founded by a team of experts with decades of experience in electronic components sourcing and distribution.

 We understand that every customer has unique needs, and we strive to work with each customer to find the right components and solutions for their projects. 
 <br/>

We are also committed to sustainability and environmental responsibility. We work with suppliers and manufacturers who prioritize sustainability, and we minimize waste in our operations. 

We believe that our success is tied to the success of our customers. 
We look forward to work with you in bringing your projects to life.





                                </p>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <ul class="ffamily06">
                                            <li><i class="bx bx-check-circle"></i>Extensive inventory</li>
                                            <li><i class="bx bx-check-circle"></i> Customer focus</li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <ul class="ffamily06">
                                            <li><i class="bx bx-check-circle"></i>Delivery and logistics</li>
                                            <li><i class="bx bx-check-circle"></i>Sustainability</li>
                                        </ul>
                                    </div>
                                </div>
                            </ngx-tab>
                            <ngx-tab tabTitle="Why Us?">
                                <p class="ffamily06">
                                    We source our components from trusted suppliers and manufacturers around the world, and we work hard to negotiate the best prices for our customers. 


                                    Our commitment to quality assurance is also a top priority. We have a rigorous process for ensuring that all components we source meet our high standards. This helps prevent our customers from receiving faulty or counterfeit components, which can save time and money in the long run.
                                </p>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <ul class="ffamily06">
                                            <li><i class="bx bx-check-circle"></i>Competitive pricing</li>
                                            <li><i class="bx bx-check-circle"></i> Quality assurance</li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <ul class="ffamily06">
                                            <li><i class="bx bx-check-circle"></i>Fast delivery</li>
                                            <li><i class="bx bx-check-circle"></i> Technical expertise</li>
                                        </ul>
                                    </div>
                                </div>
                            </ngx-tab> 
                        </ngx-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>