<section class="pricing-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <div class="section-title ffamily06">
                <h3 class="color11 text-lg">Our Department</h3>
                <h4 class="ffamily03">Meet Our Talented & Passionate Team</h4>
            </div> 
        </div>
        <div class="px-lg-5 mx-lg-5 px-2">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="box p-2 p-md-3 bg02 border-shape">
                        <div class="single-pricing overly-one">
                            <div class="overly-two">
                                <div class="pricing-title">
                                    <h2 class="ffamily06">Logistics</h2>
                                </div>
                                <ul class="ffamily03">
                                    <li>Logistics Supervisor</li>
                                    <li>John</li>
                                    <li class="">l1@naamel.com</li>
                                </ul>
                                <div class="pricing-shape-3 ffamily06">
                                    <i class="bx bxs-truck light04 pos-icon"></i>
                                </div>
                                <!-- <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                                <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="box p-2 p-md-3 bg07 border-shape">
                        <div class="single-pricing overly-one">
                            <div class="overly-two">
                                <div class="pricing-title">
                                    <h2 class="ffamily06">Sales</h2>
                                </div>
                                <ul class="ffamily03">
                                    <li>sales supervisor</li>
                                    <li>Smith</li>
                                    <li>sales3@naamel.com</li>
                                </ul>
                                <!-- <div class="icon-box">
                                    <i class="bx bx-euro text03 pos-icon"></i>
                                </div> -->
                                <div class="pricing-shape-3 ffamily06"><i class="bx bx-euro light04 pos-icon"></i></div>
                                <!-- <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                                <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="box p-2 p-md-3 bg06 border-shape">
                        <div class="single-pricing overly-one">
                            <div class="overly-two">
                                <div class="pricing-title">
                                    <h2 class="ffamily06">Purchasing</h2>
                                </div>
                                <ul class="ffamily03">
                                    <li>Purchasing Supervisor</li>
                                    <li>Vicky</li>
                                    <li>p1@naamel.com</li>
                                </ul>
                                <div class="pricing-shape-3 ffamily06">
                                    <i class="bx bx-shopping-bag light04 pos-icon"></i>
                                </div>
                                <!-- <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                                <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>