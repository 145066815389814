import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
// import { NgxImageCompressService } from 'ngx-image-compress';
@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
  providers: [DatePipe],
})
export class ProductsPageComponent implements OnInit {
  myThumbnail = 'https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg';
  myFullresImage =
    'https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg';
  productList = [];
  page: number = 1;
  totalRecords: any;
  searchPartNo='';
  constructor(
    private productService: ProductService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getProductList({ first: 0, rows: 10 });
  }

  getProductList(pageData: any) {
    this.productService.loadingObs.next(true);
    this.productList = [];
    const pagination = {
      pageNo: pageData.first + 1,
      pageSize: pageData.rows,
      searchParam: this.searchPartNo,
    };
    this.productService.getProductList(pagination).subscribe((data: any) => {
      this.totalRecords = data.totalRecords;
      this.productList = data.productsList;
      this.productList.forEach((element) => {
        element['mainPic'] = `https://101.53.158.225/naam-docs/product_images/${element.mainImage}`;// 'data:image/png;base64,' + element.mainPic;
        element['latestUpdateDate'] = this.datePipe.transform(
          this.getRandomDateWithinLastFiveDays(),
          'dd/MM/yyyy'
        );
      });
      this.productService.loadingObs.next(false);
    },err=>{
      this.productService.loadingObs.next(false);
    });
  }
  goToDetails(p) {
    this.router.navigate([`/product/${p.partNo}`]);
  }
  pageChanged(event: any) {
    // this.productList = event; 
    this.page = event;
    let pageData = {
      first:event,
      rows:10
    }

    this.getProductList(pageData);
    // this.cdr.detectChanges();
  }
  getRandomDateWithinLastFiveDays(): Date {
    const today = new Date(); // Get the current date
    const fiveDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 4
    ); // Subtract 4 from the current day to get 5 days ago
    const randomTime =
      Math.random() * (today.getTime() - fiveDaysAgo.getTime()) +
      fiveDaysAgo.getTime(); // Get a random time between 5 days ago and now
    const randomDate = new Date(randomTime); // Create a new date object from the random time
    return randomDate;
  }
}
