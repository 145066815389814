<!-- Start Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo/NaamEle.png" alt="Image" style="height: 130px;"></a>
                    <p class="ffamily06">We are a R&D and manufacturing company which specialize in the design, production and sales of electronic products.</p>
                    <ul class="social-icon"> 
                        <li><a target="_blank" href="https://www.linkedin.com/company/naam-elektroonika-o%C3%BC/"><i class="bx bxl-linkedin-square"></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget ffamily06">
                    <h3>Address</h3>
                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            NAAM Elektroonika OÜ
                            Harju maakond, Tallinn, Kesklinna linnaosa, Sakala tn 7-2, 10141
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info@naamel.com">info@naamel.com</a>
                        </li>
                        <li class="mb-2">
                            <i class="bx bxs-phone-call"></i>
                            <a href="tel:+37256306059">+372 56306059</a> 
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget ffamily06">
                    <h3>Services</h3>
                    <ul class="import-link">
                        <li><a routerLink="/">Testing</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <!-- <div class="single-footer-widget ffamily06">
                    <h3>Hot Products</h3>
                    <ul class="import-link">
                        <li><a routerLink="/">MAX231MJD/883B</a></li>
                        <li><a routerLink="/">IC71V08F32BS08-85B</a></li>
                        <li><a routerLink="/">FDMS7603S</a></li>
                        <li><a routerLink="/">EC4407KF</a></li>
                        <li><a routerLink="/">BF245C/D74Z(L34Z)</a></li> 
                    </ul>
                </div> -->
                <div class="single-footer-widget ffamily06">
                    <h3>Core Departments</h3>
                    <ul class="import-link">
                        <li><a routerLink="/">Logistics</a></li>
                        <li><a routerLink="/">Sales</a></li>
                        <li><a routerLink="/">Purchasing</a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-12">
                <p>© Designed & Developed by <a href="https://secotechnologies.in/" target="_blank">Seco Technologies</a></p>
            </div>

            <!-- <div class="col-lg-6 col-md-6">
                <ul class="footer-menu">
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                </ul>
            </div> -->
        </div>
    </div>
</div>
<!-- End Copy Right Area -->