import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit,AfterViewInit {
  currentSection: string = '';
  @ViewChild('Home') homeElement: ElementRef;
  @ViewChild('AboutUs') aboutElement: ElementRef;
  @ViewChild('Services') serviceElement: ElementRef;
  @ViewChild('Department') departmentElement: ElementRef;
  @ViewChild('ContactUs') contactElement: ElementRef;

  public currentActive = 0;
  public homeOffset: Number = null;
  public aboutOffset: Number = null;
  public serviceOffset: Number = null;
  public departmentOffset: Number = null;
  public contactOffset: Number = null;

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      
    this.homeOffset = this.homeElement.nativeElement.offsetTop;
    this.aboutOffset = this.aboutElement.nativeElement.offsetTop;
    this.serviceOffset = this.serviceElement.nativeElement.offsetTop;
    this.departmentOffset = this.departmentElement.nativeElement.offsetTop;
    this.contactOffset = this.contactElement.nativeElement.offsetTop;
    }, 2000);
  }
  goToSection(event){
    if(event!=='Products' && event!=='Manufacture'){
    setTimeout(() => {
        var my_element = document.getElementById(event);

    my_element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    }, 0);
    }
  
  }
  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.aboutOffset) {
      this.currentActive = 1;
      this.currentSection = 'Home';
    } else if (window.pageYOffset+300 >= (this.aboutOffset) && window.pageYOffset < this.serviceOffset) {
      this.currentActive = 2;
      this.currentSection = 'About Us';
    } else if (window.pageYOffset+300 >= this.serviceOffset && window.pageYOffset < this.departmentOffset) {
      this.currentActive = 3;
      this.currentSection = 'Services';
    } else if (window.pageYOffset+300 >= this.departmentOffset && window.pageYOffset < this.contactOffset) {
      this.currentActive = 4;
      this.currentSection = 'Department';
    } else if (window.pageYOffset+300 >= this.contactOffset) {
      this.currentActive = 5;
      this.currentSection = 'Contact Us';
    }  else {
      this.currentActive = 0;
      this.currentSection = '';
    } 
  }
 
  
}