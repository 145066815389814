<app-navbar-style-two (goToSection)="goToSection($event)" [currentSection]="currentSection"></app-navbar-style-two>

<section #Home>
<app-hometwo-banner id="Home"></app-hometwo-banner>
</section>
<!-- <section>
<app-hometwo-features></app-hometwo-features></section> -->


<!-- <app-hometwo-approach></app-hometwo-approach> -->
<section #AboutUs>
<app-aboutpage-about id="About Us"></app-aboutpage-about>
</section>

<!-- <app-our-challenges></app-our-challenges> -->
<section #Services>
<app-hometwo-services id="Services"></app-hometwo-services>
</section>
 
<app-partner></app-partner>
<section #Department>
    <app-hometwo-department id="Department"></app-hometwo-department>
</section>


<section #ContactUs>
<app-contact-page id="Contact Us"></app-contact-page>
</section>
<!-- <app-hometwo-pricing></app-hometwo-pricing> -->

<!-- <app-funfacts></app-funfacts> -->

<!-- <section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->


<!-- <app-hometwo-blog></app-hometwo-blog> -->