import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss'],
})
export class NavbarStyleTwoComponent implements OnInit,OnChanges {
  @Output() goToSection = new EventEmitter<string>();
  @Input() currentSection: string='Home';
  constructor(private router:Router) {}

  ngOnChanges(changes:SimpleChanges): void {
    console.log(changes);
    
    if(changes.currentSection.currentValue){
      this.navigateSection(changes.currentSection.currentValue,false);
    }
  }
  ngOnInit(): void {
    if(localStorage.getItem('section')){
      this.navigateSection(localStorage.getItem('section'),true);
    }
  }
  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  navigateSection(section: string,execute:boolean=true) {
    if(section!=='Products' && section!=='Manufacture') {
        this.router.navigate(['/']);
     }
    localStorage.setItem('section', section);
    
    var d = document.getElementsByClassName('nav-link');
    for (let i = 0; i < d.length; i++) {
        if(d[i].innerHTML !== section){
        d[i].classList.remove('active');
        } else {
        d[i].classList.add('active');
        }

    }
  if(execute){
    this.goToSection.emit(section);
  }

  }
}
