import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators'; 
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token = '';
  constructor(
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const userData = JSON.parse(sessionStorage.getItem('user') as string);
    let auth: any; 
    if (userData) {
      this.token = userData.token;
    }
 
      auth = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${this.token}`)
          .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'application/json'),
        responseType: 'json'
      });
  

    // this.loader.show();
    return next.handle(auth).pipe(
      finalize(() => {
        // this.loader.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        // this.loader.hide();
        if (error.status === 403) {
          // permission error
        }
        if (error.status === 401) {
          // this.commonService.showMessage('UnAuthorized', 'error');
        }
        return throwError(error);
      })
    );
  }
}