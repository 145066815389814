
<div  class="overlay" *ngIf="loading" >
    <div class="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
  </div> 
  <div *ngIf="loading" style="position: fixed !important;"
   
    class="cdk-overlay-backdrop cdk-overlay-trans-backdrop cdk-overlay-backdrop-showing loading"
  ></div> 
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer>
<ngx-scrolltop class="xyz"></ngx-scrolltop>